.wrapper {
  max-width: 1200px;
  margin: 1rem auto 3rem;
  width: 90%;
  // display: flex;
  // justify-content: center;
}
.container {
  // max-width: 700px;
  // width: 100%;
  h2 {
    font-family: var(--font-header--normal);
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    width: 100%;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    padding: 0 0.5rem;
  }
  input {
    max-width: 300px;
    height: 2rem;
  }
  textarea {
    width: calc(100% - 1rem);
    max-width: 700px;
    padding: 0.5rem;
  }
  button {
    background-color: var(--color-primary);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-family: var(--font-main);
    color: var(--color-main);
    font-weight: 600;
  }
}