.footer {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  font-size: 0.75rem;
}
.credits {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg, a {
    margin: 0 0.25rem;
  }
}
