.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.kubi,
.menodue {
  margin: 3rem auto;
  a + a {
    margin-left: 1rem;
  }
  width: 100%;
}

.kubi {
  color: var(--color-kubi);
  @media (min-width: 850px) {
    margin-right: 3rem;
  }
}
.menodue {
  color: var(--color-menodue);
}

.social,
.social a {
  display: flex;
  align-items: center;
}

.link {
  display: flex;
}
.link:hover {
  :global(.plettro1) {
    transform: rotate(-360deg)
  }
  :global(.plettro2) {
    transform: rotate(360deg)
  }
}

.title {
  font-family: var(--font-header);
  font-size: 1.5rem;
  a {
    text-decoration: none;
  };
}
.subtitle {
  font-family: var(--font-header--normal);
  text-transform: uppercase;
  font-size: 0.75rem;
  display: block;
}